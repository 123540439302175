	 import Alpine from "alpinejs";
  import collapse from "@alpinejs/collapse";
  import intersect from "@alpinejs/intersect";
  import focus from "@alpinejs/focus";
  import mask from "@alpinejs/mask";
  import Tooltip from "@ryangjchandler/alpine-tooltip";
  import Clipboard from "@ryangjchandler/alpine-clipboard";

  Alpine.plugin(intersect);
  Alpine.plugin(collapse);
  Alpine.plugin(focus);
  Alpine.plugin(mask);
  Alpine.plugin(Tooltip);
  Alpine.plugin(Clipboard);

 // window.Alpine = Alpine;
 // Alpine.start();
  
  if (!window.Alpine) (window.Alpine = Alpine).start();
